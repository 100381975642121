import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { DonationPortalState } from "../redux/store";
import IOrganization from "@matchstik/models/.dist/interfaces/IOrganization";
import { Colors } from "../styles/Colors";
import ScreenHeader from '../components/ScreenHeader';
import Label from '../elements/Label';
import useShowNext from "../hooks/useShowNext.hook";
import { useQuery } from "@apollo/react-hooks";
import * as Price from "@matchstik/utils/.dist/price";
import campaignsSrc from "../assets/images/campaignsimage.jpg";
import GET_LIST_CAMPAIGN from "./../graphql/queries/listCampaign.query";
import Icon, { Icons } from '../elements/Icon';
import * as AppActions from '../redux/actions/app.actions';
import ICreateDonationParams from "@matchstik/models/.dist/interfaces/ICreateDonationParams";
import { OrganizationStatusEnum } from "@matchstik/models/.dist/enums/OrganizationStatusEnum";

type ContainerProps = {
  showNext: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  margin-top: -50px;
  background-color: ${Colors.White};
  border-radius: 15px;
  overflow: hidden;
  height: calc(100% - ${props => props.showNext ? "335px" : "275px"});
  @media screen and (max-device-width: 991px) and (orientation: landscape){
    overflow:initial;
    height:auto;
   }
`;

const Content = styled.div`
  padding: 0 30px;
  overflow: auto;
  overflow-x: hidden;
  padding-top: 12px;
  padding-bottom: 12px;
  height: calc(100% - 84px);
  @media screen and (max-device-width: 991px) and (orientation: landscape){
    overflow: initial;
    overflow-x: initial;
   }
`;

export const CampaignsList = styled.div<any>` 
  background: #FFFFFF;
  border: ${({ active }) => (active ? '2px solid #ff0080' : '1px solid #c4c4c4')};
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  margin:0 0 30px 0;
  cursor: pointer;
  &:last-child{
  margin:0px;
  }
  @media (max-width:576px){
      display:block;
    }
`;
export const CampaignsImage = styled.div<any>`
  width:130px;
  background-image:url(${props => props.posterUrl});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  @media (max-width:576px){
      width:100%;
      border-bottom-left-radius:0px;
      border-top-right-radius: 10px;
      height:150px;
    }
`;
export const CampaignsDesc = styled.div`
    padding:12px 15px;
    width:calc(100% - 130px);
    display:flex;
    align-items:center;
    @media (max-width:1199px){
        padding:12px;
        width:calc(100% - 150px);
      }
      @media (max-width:576px){
        width:calc(100% - 24px);
      }
`;
export const CampaignsInner = styled.div`
width:100%;
`;

export const CampaignsHeading = styled.div`
font-weight: 600;
font-size: 14px;
line-height: normal;
display: flex; justify-content: space-between;
color:${Colors.Pink};
margin:0 0 13px 0;
position:relative;
@media (max-width:1199px){
    font-size:18px;
    line-height:27px;
    margin:0 0 8px 0;   
}
`;
export const CampaignsSection = styled.div`
  display:block;
  // justify-content:space-between;
  // align-items: flex-end;
  // @media (max-width:1199px){
  //   display:block;
  // }
  // @media (max-width:767px){
  //   display:flex;
  // }
  // @media (max-width:479px){
  //   display:block;
  // }
`;
export const CampaignsProgress = styled.div`
     height:4px;
     background-color:${Colors.Grey5};
     margin:0 0 13px 0;
    // height: height,
    //     width: '100%',
    //     backgroundColor: 'whitesmoke',
    //     borderRadius: 40,
    //     margin: 50
}
 `;
export const CampaignsProgressBar = styled.div<any>`
     height: 100%;
     font-size: 12px;
     line-height: 20px;
     color: #fff;
     text-align: center;
     background-color:${Colors.Pink}; 
     width:${props => props.progress};
     position:relative;
     &:after{
         content: "";
         background:${Colors.Pink}; 
         position: absolute;
         right: -2px;
         width:8px;
         height:8px;
         border-radius: 50%;
         top: -2px;
     }
 `;

export const ProgressText = styled.span`
       padding: 10,
        color: 'black',
        fontWeight: 900
`;
export const LeftPanel = styled.div`
margin:0 0 13px 0;
@media (max-width:1199px){
    margin:0 0 13px 0;
  }
  @media (max-width:767px){
    margin:0;
  }
@media (max-width:479px){
    margin:0 0 13px 0;
  }
`;

export const Amount = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height:normal;
    color:${Colors.Black};
    @media (max-width:1199px){
        font-size:14px;
        line-height:20px; 
    }
`;
export const Desc = styled.div`
    font-weight:400;
    font-size: 12px;
    line-height:normal;
    @media (max-width:1199px){
        font-size:14px;
        line-height:20px; 
    }
`;
export const RightPanel = styled.div`
display:flex;
justify-content:space-between;
`;
export const Matchers = styled.div`
   margin:0 20px 0 0;
   font-weight: bold;
   font-size: 10px;
   line-height: 17px;
   color:${Colors.Grey2};
   display: flex;
   @media (max-width:1199px){
    font-size:11px;
    line-height:15px; 
  }
`;
export const Donors = styled.div`
font-weight: bold;
font-size: 10px;
line-height: 17px;
color:${Colors.Grey2};
display: flex;
@media (max-width:1199px){
    font-size:11px;
    line-height:15px; 
  }
`;
export const RightLayout = styled.div`
    width:30%;
    @media (max-width:1199px){
        width:35%;   
    }
    @media (max-width:767px){
        width:100%;
    }
    `;
export const CreateButton = styled.div`
      margin:0 0 30px 0;
    `;

export const CardLayout = styled.div`
        background: #FFFFFF;
        border: 1px solid #F2F2F2;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
    `;
export const CardHeader = styled.div`
    font-size:12px;
    line-height:18px;
    padding: 14px 15px;
    color: ${Colors.Grey1};
    border-bottom: 1px solid ${Colors.Grey6};
    display:flex;
    font-weight:500;
    `;
export const CardBody = styled.div`
    `;
export const CardList = styled.div`
    border: 1px solid #F2F2F2;
    padding:20px;
    display: flex;
    align-items: center;
 `;
export const CardImage = styled.div`
 background: #FFFFFF;
 border: 1px solid #C4C4C4;
 width:78px;
 height:78px;
 border-radius:50%;
 margin-right:18px;
 text-align:center;
 display: flex;
 align-items: center;
 justify-content: center;
 `;
export const ThumbImage = styled.img`
   width: 60px;
   height: 60px;
 `;
export const CardDesc = styled.div`
 width: calc(100% - 96px);
 `;
export const CardHeading = styled.div`
     font-weight: 600;
     font-size: 14px;
     color: ${Colors.Grey1};
 `;
export const CardPrice = styled.div`
     font-size: 14px;
     font-weight: 400;
     color: ${Colors.Grey3};  
     margin:0 0 8px 0;

 `;
export const CardProgress = styled.div`
     height:6px;
     background-color:${Colors.Grey5};
}
 `;
export const NoCampaignText = styled.div`
    font-size: 26px;
    font-weight: 600;
    color: #333333;
    border: 1px solid ${Colors.Grey6};;
    padding:100px 15px;
    border-radius: 10px;
    text-align: center;
}
 `;
export const CardProgressBar = styled.div`
     height: 100%;
     font-size: 12px;
     line-height: 20px;
     color: #fff;
     text-align: center;
     background-color:${Colors.Pink}; 
     width:85%;
     position:relative;
     &:after{
         content: "";
         background:${Colors.Pink}; 
         position: absolute;
         right: -2px;
         width: 12px;
         height: 12px;
         border-radius: 50%;
         top: -3px;
     }`;

const MessageContent = styled.div`
     margin-top: 50%;
     padding: 0 30px;
     overflow-y: auto;
     overflow-x: hidden;
     max-height: 33vh;
     padding-top: 12px;
   `;

const BigText = styled.div`
     font-size: 1.4rem;
     font-weight: 800;
     color: ${Colors.Grey1};
   `;

type CampaignProps = {
  organization: IOrganization;
};

const Campaign: React.FC<CampaignProps> = ({ organization }) => {
  /** State **/
  const { app } = useSelector((state: DonationPortalState) => state);

  const urlCampaignId = window.location.href.split("campaignId")[1]?.split("&&")[0] !== undefined ? window.location.href.split("campaignId")[1].split("&&")[0] : "";

  const showNext = useShowNext();
  const {
    createDonationParams: { campaignId },
  } = app;

  /** Actions **/
  const dispatch = useDispatch();
  const setCreateDonationParams = (params: Partial<ICreateDonationParams>) =>
    dispatch(AppActions.setCreateDonationParams(params));

  const selectedCampaign = (campaign: any) => {
    setCreateDonationParams({ campaignId: campaign._id });
  };


  /** GraphQL **/
  const { data, loading } = useQuery(GET_LIST_CAMPAIGN, {
    variables: {
      organizationId: organization._id
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const campaignDetail = data?.listCampaign || []
      const filteredData = campaignDetail?.filter((campaign: any) => campaign.active && campaign.isDefault);
      setCreateDonationParams({ campaignId: !campaignId && !urlCampaignId ? filteredData[0]?._id : campaignId || urlCampaignId });
    },
  });
  const campaignDetail = data?.listCampaign || []
  const filteredData = campaignDetail?.filter((campaign: any) => campaign.active);

  /** Render **/
  return (

    <Container showNext={showNext}>
      <ScreenHeader title="Contact Info" />
      {(organization.hasActiveSubscription &&
        organization.status === OrganizationStatusEnum.Active &&
        organization.stripeConnectId) ||
        (!organization.needSubscription && organization.stripeConnectId) ? (
        <Content>
          <Label text="Choose your Campaign:" />
          {filteredData?.map((campaign: any, i:any) => {

            let totalDonationAmount = campaign.totalDonationAmount === null ? "" : campaign.totalDonationAmount
            return (
              <CampaignsList key={i} active={campaign._id === campaignId} onClick={() => selectedCampaign(campaign)}  >
                <CampaignsImage posterUrl={campaign.posterUrl ? campaign.posterUrl : campaignsSrc} />
                <CampaignsDesc>
                  <CampaignsInner>
                    <CampaignsHeading>{campaign.name}</CampaignsHeading>
                    {campaign.completed !== -1 && <CampaignsProgress>
                      <CampaignsProgressBar progress={campaign.completed + "%"} >
                      </CampaignsProgressBar>
                    </CampaignsProgress>}
                    <CampaignsSection>
                      <LeftPanel>
                        <Amount>${Price.output(totalDonationAmount, true)} </Amount>
                        {campaign.goal !== null && campaign.goal !== "" && <Desc>raised of ${Price.output(campaign.goal, true)} goal</Desc>}
                      </LeftPanel>
                      <RightPanel>

                        {campaign.totalMatchers ?
                          <Matchers><Icon icon={Icons.FireRegular} size={10} color={Colors.Grey2} margin='0px 6px 0px 0px' />{campaign.totalMatchers}x Multiplier </Matchers> : ""}
                        <Donors><Icon icon={Icons.HandHoldingHeart} size={10} color={Colors.Grey2} margin='0px 6px 0px 0px' />{campaign.donors} donors</Donors>
                      </RightPanel>
                    </CampaignsSection>
                  </CampaignsInner>
                </CampaignsDesc>
              </CampaignsList>
            );
          })}
        </Content>
      ) : (
        <MessageContent>
          <BigText>Currently this organization can't receive donations</BigText>
        </MessageContent>
      )}
    </Container>
  );
};

export default Campaign;

