import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { DonationPortalState } from '../redux/store';
import IOrganization from "@matchstik/models/.dist/interfaces/IOrganization";
import { Colors } from "../styles/Colors";
import { Icons } from "../elements/Icon";
import ScreenHeader from '../components/ScreenHeader';
import * as AppActions from '../redux/actions/app.actions';
import Select from '../components/Select';
import ICreateDonationParams from "@matchstik/models/.dist/interfaces/ICreateDonationParams";
import { DonationTypesEnum } from '@matchstik/models/.dist/enums/DonationTypesEnum';
import Label from "../elements/Label";
import useShowNext from "../hooks/useShowNext.hook";
type ContainerProps = {
  showNext: boolean;
}
const Container = styled.div<ContainerProps>`
  position: relative;
  margin-top: -50px;
  background-color: ${Colors.White};
  border-radius: 15px;
  overflow: hidden;
  height: calc(100% - ${props => props.showNext ?"335px": "275px"});
  @media screen and (max-device-width: 991px) and (orientation: landscape){
    overflow:initial;
    height:auto;
   }
`;
const Content = styled.div`
  padding: 0 30px;
  overflow: auto;
  overflow-x: hidden;
  padding-top: 12px;
  padding-bottom: 12px;
  height: calc(100% - 84px);
  @media screen and (max-device-width: 991px) and (orientation: landscape){
    overflow: initial;
    overflow-x: initial;
   }
`;

type DonationTypeProps = {
  organization: IOrganization;
};

const DonationType: React.FC<DonationTypeProps> = ({ organization }) => {
  /** State **/
  const { app } = useSelector((state: DonationPortalState) => state);
  const showNext = useShowNext();
  /** Actions **/
  const {
    createDonationParams: {
      type,
    }
  } = app;
  const dispatch = useDispatch();
  const setCreateDonationParams = (params: Partial<ICreateDonationParams>) =>
    dispatch(AppActions.setCreateDonationParams(params));

  /** Render **/
  const options = [
    {
      text: "Recurring Donation",
      icon: Icons.UserSolid,
      onClick: () => {
        setCreateDonationParams({ type: DonationTypesEnum.FirstMembershipDonation });
      },
      active: type === DonationTypesEnum.FirstMembershipDonation,
    },
    {
      text: "One-Time Donation",
      icon: Icons.UsersSolid,
      onClick: () => {
        setCreateDonationParams({ type: DonationTypesEnum.OneTimeDonation });
      },
      active: type === DonationTypesEnum.OneTimeDonation,
    },
  ];


  return (
    <Container showNext={showNext}>
      <ScreenHeader title="Make 12X the impact and become a member" />
      <Content>
        <Label text={organization.recurringDonationMessage || 'Make 12X the impact and become a member'} />
        <Select options={options} />
      </Content>
    </Container>
  );
};

export default DonationType;
