import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Colors } from '../styles/Colors';
import Button, { ButtonTypes, ButtonStates } from '../elements/Button';
import Icon, { Icons } from '../elements/Icon';
import * as Polished from 'polished';
import Loader, { LoaderSizes } from '../elements/Loader';
import { motion } from 'framer-motion';
import * as AppActions from "../redux/actions/app.actions";
import useOrganization from '../hooks/useOrganization.hook';
import { DonationPortalState } from '../redux/store';
import { ScreenEnum } from '../redux/reducers/app.reducer';
import {
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import * as StripeService from '../utils/StripeService';
import OrganizationInfo from '../components/OrganizationInfo';
import Campaign from '../screens/Campaign';
import Donate from '../screens/Donate';
import DonationType from '../screens/DonationType';
import DonorType from '../screens/DonorType';
import Email from '../screens/Email';
import UserInfo from '../screens/UserInfo';
import BusinessInfo from '../screens/BusinessInfo';
import UserAddressInfo from '../screens/UserAddressInfo';
import BusinessAddressInfo from '../screens/BusinessAddressInfo';
import EnterPayment from '../screens/EnterPayment';
import ConfirmDonation from '../screens/ConfirmDonation';
import ThankYou from "../screens/ThankYou";
import Controls from '../components/Controls';
import Flex from "../elements/Flex";
import { DONATION_URL } from "../env";
import CopyToClipboard from 'react-copy-to-clipboard';
import { inIframe } from '../utils/Tools';
import * as Embed from "../utils/Embed";
import ICreateDonationParams, { createDonationParamsState } from "@matchstik/models/.dist/interfaces/ICreateDonationParams";
import { useLocation } from 'react-router-dom';


var QRCode = require('qrcode.react');

const { useState } = React

const MainContainer = styled(motion.div) <any>`
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 400px;
  background-color: ${Colors.White};
  overflow: hidden;
  flex-direction:column;
  display:flex;
  @media screen and (min-width: 768px) {
    max-height: 700px;
    height: 96vh;
    border-radius: 15px;
  }
  @media screen and (max-device-width: 991px) and (orientation: landscape){
    overflow:auto;
    height:80vh;
  }
`;

type MainPopContainerTypes = {};

const MainPopContainer: React.FC<MainPopContainerTypes> = ({ children }) => {
  return (
    <MainContainer
      id="MATCHSTIK_DONATION_MODAL"
      initial={{ scale: 0.9 }}
      animate={{ scale: 1 }}
      transition={{
        duration: 0.5,
        type: "spring",
        stiffness: 260,
        damping: 20
      }}
    >
      {children}
    </MainContainer>
  );
}



const Container = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  margin-bottom: 200px;
  margin-bottom: 0px;
  z-index: 1000;
  @media screen and (max-device-width: 991px) and (orientation: landscape){
    overflow:initial;
    height: auto;
   }
`;

const ShareModal = styled.div`
    position: absolute;
    border-radius: 7px;
    background: #f5f5f5;
    width: calc(100% - 80px);
    height: calc(100% - 270px);
    padding:20px;
    margin:20px;
    z-index: 9999;
    box-shadow: 0 0 1px 1px rgba(0,0,0,0.1), 0 15px 30px 0 rgba(0,0,0,0.2);
    height: 444px;
`;

const ShareModalInfo = styled.div`
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;
  z-index: 1000;

  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const OrgLogoContainer = styled.div`
  background-color: ${Colors.White};
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

`;

const OrgLogo = styled.img`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const ShareName = styled.div`
  font-size: 1.4rem;
  color: ${Colors.Grey2};
  font-weight: 800;
`;

const QRContainer = styled.div`
    width:100%;
    margin-top:25px;
    align-items: center;
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
  width: 60px;
  position: absolute;
  padding: 20px 30px;
  z-index:9999;
  // @media screen and (max-width: 767px){
  //   position:relative;
  //  }
`;

const Close = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1000000;

  &:hover {
    cursor: pointer;
  }
`;

const Message = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${Colors.Grey1};
  line-height: 140%;
`;

const Placeholder = styled.div`
  background-color: transparent;
  height: 200px;
  height: calc(160px + 85px);
  @media screen and (max-device-width: 991px) and (orientation: landscape){
    height:auto;
   }
`;

const Spacer = styled.div`
  position: relative;
  height: 200px;
  height: 0px;
`;

const NoOrgMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: 600;
  color: ${Colors.Grey2};
  height: 100%;
  text-align: center;
`;

type DonationPortalProps = {};

// HANDLE ERROR
const DonationPortal: React.FC<DonationPortalProps> = () => {
  /** Hooks **/
  let { organization, organizationId, loading } = useOrganization();
  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();

  const [linkText, setLinkText] = useState('Copy Share Link')

  React.useEffect(() => {
    if (stripe && elements) {
      StripeService.setStripe(stripe, elements);
    }
  }, [stripe, elements]);

  /** State **/


  const { screen } = useSelector((state: DonationPortalState) => state.app);
  const { shareModal } = useSelector((state: DonationPortalState) => state.app);
  const { app } = useSelector((state: DonationPortalState) => state);
  const setCreateDonationParams = (params: Partial<ICreateDonationParams>) =>
    dispatch(AppActions.setCreateDonationParams(params));
  const {
    createDonationParams: { donorType },
  } = app;
  const { createDonationParams } = app;


  /** Render **/
  if (!organizationId) {
    return (
      <MainPopContainer>
        <NoOrgMessage>Oops, somethign went wrong<br />Please reload the browser window.</NoOrgMessage>
      </MainPopContainer>
    );
  }

  const orgUrl = `${DONATION_URL}?organizationId=${organizationId}`;

  const openShareModal = () => {
    dispatch(AppActions.toggleShareModal(true));
  }
  
  const closeShareModal = () => {
    dispatch(AppActions.toggleShareModal(false));
    } 

  const onCopy = () => {
    setLinkText('Link copied.')
    setTimeout(() => {
      setLinkText('Copy Share Link')
    }, 3000);
  }

  return (
    <MainPopContainer>
      {(() => {
        if (!organization || loading) {

          return (
            <Flex align="center" justify="center" height="100%">
              <Loader size={LoaderSizes.Large} />
            </Flex>
          );
        }

        return (
          <Fragment>
            {shareModal ? (

              <ShareModal>
                <Close onClick={() => closeShareModal()} id="CloseDonationModal" >
                  <Icon icon={Icons.CancelCircle} color={Polished.rgba(Colors.Grey2, 0.8)} size={16} />
                </Close>
                <ShareModalInfo>
                  <OrgLogoContainer>
                    <OrgLogo src={organization?.logoUrl} />
                  </OrgLogoContainer>
                  <ShareName>{organization.name || "Unnamed Organization"}</ShareName>
                </ShareModalInfo>
                <Flex align="center" margin="20px 0 0 0">
                  <CopyToClipboard onCopy={onCopy} text={orgUrl}>
                    <Button
                      type={ButtonTypes.Submit}
                      text={linkText}
                    />
                  </CopyToClipboard>
                </Flex>
                <QRContainer>
                  <QRCode value={orgUrl} size={300} />
                </QRContainer>
              </ShareModal>

            ) : (
              <ButtonContainer>
                <Button
                  type={ButtonTypes.Submit}
                  text='Share'
                  onClick={() => openShareModal()}
                />
              </ButtonContainer>
            )}

            <OrganizationInfo organization={organization} />
            <Container>
              <Placeholder />
              {(() => {
                switch (screen) {
                  case ScreenEnum.Campaign:
                    return <Campaign organization={organization} />;
                    
                  case ScreenEnum.Donate:
                    return <Donate organization={organization} />;

                  case ScreenEnum.Email:
                    return <Email organization={organization} />;

                  case ScreenEnum.DonationType:
                    return <DonationType organization={organization} />;

                  case ScreenEnum.DonorType:
                    return <DonorType organization={organization} />;

                  case ScreenEnum.UserInfo:
                    return <UserInfo organization={organization} />;

                  case ScreenEnum.UserAddressInfo:
                    return <UserAddressInfo organization={organization} />;

                  case ScreenEnum.BusinessInfo:
                    return <BusinessInfo organization={organization} />;

                  case ScreenEnum.BusinessAddressInfo:
                    return <BusinessAddressInfo organization={organization} />;

                  case ScreenEnum.EnterPayment:
                    return <EnterPayment organization={organization} />;

                  case ScreenEnum.ConfirmDonation:
                    return <ConfirmDonation organization={organization} />;

                  case ScreenEnum.ThankYou:
                    return <ThankYou organization={organization} />;
                }
              })()}
            </Container>
            <Spacer />
            <div>
              <Controls organization={organization} />
            </div>
          </Fragment>
        );
      })()}
    </MainPopContainer>
  );
}

export default DonationPortal;
