import "./styles/index.css";
import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from "@apollo/react-hooks";
import { Provider as ReduxProvider } from 'react-redux';
import App from './App';
import client from './graphql/client';
import store from './redux/store';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

ReactDOM.render(
  <ApolloProvider client={client as any}>
    <ReduxProvider store={store}>
        <App />
    </ReduxProvider>
  </ApolloProvider>,
  document.getElementById("root")
);
