import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { DonationPortalState } from "../redux/store";
import IOrganization from "@matchstik/models/.dist/interfaces/IOrganization";
import { Colors } from "../styles/Colors";
import ICreateDonationParams from "@matchstik/models/.dist/interfaces/ICreateDonationParams";
import * as AppActions from "../redux/actions/app.actions";
import Flex from "../elements/Flex";
import * as Price from "@matchstik/utils/.dist/price";
import Checkbox from "../elements/Checkbox";
import { DONATION_URL } from "../env";
import { OrganizationStatusEnum } from "@matchstik/models/.dist/enums/OrganizationStatusEnum";
import { CoverFeesPercentEnum } from "@matchstik/models/.dist/enums/CoverFeesPercentEnum";
import { DonationTypesEnum } from "@matchstik/models/.dist/enums/DonationTypesEnum";
import { CoverFeesEnum } from "@matchstik/models/.dist/enums/CoverFeesEnum";
import useShowNext from "../hooks/useShowNext.hook";
import ScreenHeader from "../components/ScreenHeader";
import CoverFeesUtil from "@matchstik/utils/.dist/coverFees";
const CoverFeesUtilObj = new CoverFeesUtil();

type ContainerProps = {
  showNext: boolean;
};

const Container = styled.div<ContainerProps>`
  margin-top: -50px;
  background-color: ${Colors.White};
  border-radius: 15px;
  overflow: hidden;
  height: calc(100% - ${(props) => (props.showNext ? "335px" : "275px")});
  @media screen and (max-device-width: 991px) and (orientation: landscape) {
    overflow: initial;
    height: auto;
  }
`;

const Content = styled.div`
  padding: 0 30px;
  overflow: auto;
  overflow-x: hidden;
  padding-top: 12px;
  padding-bottom: 12px;
  height: calc(100% - 24px);
  @media screen and (max-device-width: 991px) and (orientation: landscape) {
    overflow: initial;
    overflow-x: initial;
  }
`;

const DollarSign = styled.div`
  font-size: 4.8rem;
  font-weight: 800;
  color: ${Colors.Grey4};
  margin-right: 5px;
`;

const DollarInput = styled.input`
  font-size: 4.8rem;
  font-weight: 800;
  color: ${Colors.Grey2};
  outline: 0px;
  // font-family: "Nunito Sans";
  border: 0px;
  width: 100%;

  ::placeholder {
    color: ${Colors.Grey2};
    // font-family: "Nunito Sans";
    font-weight: 800;
  }
`;

const BigText = styled.div`
  font-size: 1.4rem;
  font-weight: 800;
  color: ${Colors.Grey1};
`;

const Message = styled.div`
  font-size: 1.8rem;
  font-weight: 500;
  color: ${Colors.Grey1};
  line-height: 140%;
  margin-bottom: 48px;
`;

const CoverFeesText = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${Colors.Grey2};
  margin-left: 10px;
`;

type DonateProps = {
  organization: IOrganization;
};

const Donate: React.FC<DonateProps> = ({ organization }) => {
  const offlineDonation =
    window.location.href.split("&&")[1] !== undefined
      ? window.location.href.split("&&")[1]
      : "";

  /** Hooks **/
  const showNext = useShowNext();

  /** State **/
  const { app } = useSelector((state: DonationPortalState) => state);
  const {
    createDonationParams: { donationAmount, coverFees },
  } = app;
  const organizationId = organization?._id || "";
  let appliedFees = app.organizationsCache[organizationId].donorFees;

  /** Actions **/
  const dispatch = useDispatch();
  const setCreateDonationParams = (params: Partial<ICreateDonationParams>) =>
    dispatch(AppActions.setCreateDonationParams(params));
  React.useEffect(() => {
    if (offlineDonation) {
      setCreateDonationParams({
        coverFees: false,
        type: DonationTypesEnum.OfflineDonation,
      });
    } else if (appliedFees === CoverFeesEnum.NoFees) {
      setCreateDonationParams({
        coverFees: false,
      });
    }
  }, [coverFees]);

  const orgUrl = `${DONATION_URL}/organization/${organization._id}`;
  /** Render **/
  return (
    <Container showNext={showNext}>
      <ScreenHeader title="Contact Info" />
      <Content>
        <BigText>Enter your donation amount (minimum $10):</BigText>
        <Flex align="center">
          <DollarSign>$</DollarSign>
          <DollarInput
            value={donationAmount}
            placeholder="0"
            onChange={(event: React.FormEvent<HTMLInputElement>) => {
              const { value } = event.currentTarget;
              let numbers = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
              if (value.match(numbers) || value === "") {
                setCreateDonationParams({
                  donationAmount: value
                    ?.replace(new RegExp(" ", "g"), "")
                    .toString(),
                });
                // CoverFeesUtilObj.calculateAmounts(Number(donationAmount), String(organization?.donorFees));
                CoverFeesUtilObj.calculateAmounts(
                  Number(donationAmount),
                  coverFees,
                  appliedFees
                );
              }
            }}
            maxLength={8}
          />
        </Flex>
        {!offlineDonation && appliedFees !== CoverFeesEnum.NoFees ? (
          <Flex align="center" margin="20px 0 30px">
            <Checkbox
              // active={true}
              active={coverFees as boolean}
              onChange={(active: boolean) =>
                setCreateDonationParams({ coverFees: active })
              }
            />
            <CoverFeesText>Cover the donation fees</CoverFeesText>
          </Flex>
        ) : (
          <Flex align="center" margin="20px 0 30px"></Flex>
        )}
        <Message>
          {organization.message ||
            "Thank you for choosing to make a difference. Your support helps make our work possible."}
        </Message>
      </Content>
    </Container>
  );
};

export default Donate;
