import { Stripe, StripeElements } from "@stripe/stripe-js";

let globalStripe: Stripe | null = null;
let globalElements: StripeElements | null = null;

export function setStripe(stripe: Stripe, elements: StripeElements) {
  globalStripe = stripe;
  globalElements = elements;
}

type StripeInjection = {
  stripe: Stripe;
  elements: StripeElements;
}

export function stripe(): StripeInjection | null {
  if(!globalStripe || !globalElements) return null;

  return {
    stripe: globalStripe,
    elements: globalElements,
  };
}
