import React from 'react';
import styled from 'styled-components';
import { Colors } from '../styles/Colors';
import IOrganization from "@matchstik/models/.dist/interfaces/IOrganization";
import * as Polished from 'polished';
import Icon, { Icons } from '../elements/Icon';
import * as Embed from "../utils/Embed";
import { useSelector, useDispatch } from 'react-redux';
import { DonationPortalState } from '../redux/store';
import { inIframe } from "../utils/Tools";

type ContainerProps = {
  image: string;
};

const Container = styled.div<ContainerProps>`
  position: absolute;
  top: 0px;
  /* z-index: 1000; */
  display: flex;
  align-items: flex-end;
  width: calc(100% - 40px);
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  background-origin: unset;
  padding: 20px 20px 65px;
  height:160px;
  /* max-height: 210px; */
  @media screen and (max-device-width: 991px) and (orientation: landscape){
    padding: 100px 20px 65px;
    position:relative;
  }
  @media screen and (max-device-width:500px) and (orientation: landscape){
    padding: 125px 20px 65px;
    position:relative;
  }
`;

const Info = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;
  z-index: 1000;

  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Gradient = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: ${Polished.rgba(Colors.Black, 0.6)};

  @media screen and (min-width: 768px) {
    border-radius: 15px 15px 0 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 15.67%, rgba(0, 0, 0, 0.1) 100%);
  }
`;

const Name = styled.div`
  font-size: 1.8rem;
  color: ${Colors.White};
  font-weight: 600;
`;

const OrgLogoContainer = styled.div`
  background-color: ${Colors.White};
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

`;

const OrgLogo = styled.img`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const Subtitle = styled.div`
  font-size: 1.4rem;
  color: ${Colors.White};
  margin-bottom: 3px;
  font-weight: 600;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Close = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1000000;

  &:hover {
    cursor: pointer;
  }
`;

type OrganizationInfoProps = {
  organization: IOrganization;
};


const OrganizationInfo: React.FC<OrganizationInfoProps> = ({ organization }) => {
  /** State **/

  const { shareModal } = useSelector((state: DonationPortalState) => state.app );


  /** Render **/
  return (
    <Container image={organization?.posterUrl ?? ""}>
      { !shareModal && inIframe() && (
          <Close onClick={() => Embed.close()}>
            <Icon icon={Icons.CancelCircle} color={Polished.rgba(Colors.White, 0.8)} size={16}/>
          </Close>
      )}
      <Gradient />
      <Info>
        <OrgLogoContainer>
          <OrgLogo src={organization?.logoUrl} />
        </OrgLogoContainer>
        <Name>{organization.name || "Unnamed Organization"}</Name>
        {/* {organization.subtitle && <Subtitle>{organization.subtitle}</Subtitle>} */}
      </Info>
    </Container>
  );
};

export default OrganizationInfo;
