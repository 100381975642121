import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from "../styles/Colors";

import { faCcAmex as Amex } from "@fortawesome/free-brands-svg-icons";
import { faCcDiscover as Discover } from "@fortawesome/free-brands-svg-icons";
import { faCcMastercard as Mastercard } from "@fortawesome/free-brands-svg-icons";
import { faCcVisa as Visa } from "@fortawesome/free-brands-svg-icons";
import { faAnalytics as AnalyticsLight } from "@fortawesome/pro-light-svg-icons";
import { faBell as BellLight } from "@fortawesome/pro-light-svg-icons";
import { faCashRegister as BoxOfficeLight } from "@fortawesome/pro-light-svg-icons";
import { faBullhorn as BullHornLight } from "@fortawesome/pro-light-svg-icons";
import { faCalculator as CalculatorLight } from "@fortawesome/pro-light-svg-icons";
import { faCalendarDay as CalendarDayLight } from "@fortawesome/pro-light-svg-icons";
import { faCalendarAlt as CalendarLight } from "@fortawesome/pro-light-svg-icons";
import { faCalendarStar as CalendarStarLight } from "@fortawesome/pro-light-svg-icons";
import { faTimes as CancelLight } from "@fortawesome/pro-light-svg-icons";
import { faCheck as CheckLight } from "@fortawesome/pro-light-svg-icons";
import { faCopy as CopyLight } from "@fortawesome/pro-light-svg-icons";
import { faEdit as EditLight } from "@fortawesome/pro-light-svg-icons";
import { faEnvelope as EnvelopeLight } from "@fortawesome/pro-light-svg-icons";
import { faEnvelopeOpenText as EnvelopeOpenRegular } from "@fortawesome/pro-light-svg-icons";
import { faFileExport as ExportLight } from "@fortawesome/pro-light-svg-icons";
import { faEye as EyeLight } from "@fortawesome/pro-light-svg-icons";
import { faFilter as FilterLight } from "@fortawesome/pro-light-svg-icons";
import { faGift as GiftLight } from "@fortawesome/pro-light-svg-icons";
import { faGlobe as GlobeLinesLight } from "@fortawesome/pro-light-svg-icons";
import { faQuestionCircle as HelpLight } from "@fortawesome/pro-light-svg-icons";
import { faHome as HomeLight } from "@fortawesome/pro-light-svg-icons";
import { faLink as LinkLight } from "@fortawesome/pro-light-svg-icons";
import { faLock as Lock } from "@fortawesome/pro-light-svg-icons";
import { faMapMarkerAlt as MapPinLight } from "@fortawesome/pro-light-svg-icons";
import { faChartBar as MetricsLight } from "@fortawesome/pro-light-svg-icons";
import { faMicrophoneAlt as MicrophoneLight } from "@fortawesome/pro-light-svg-icons";
import { faMinusCircle as MinusCircleLight } from "@fortawesome/pro-light-svg-icons";
import { faMobileAlt as MobileLight } from "@fortawesome/pro-light-svg-icons";
import { faBuilding as OrganizationLight } from "@fortawesome/pro-light-svg-icons";
import { faPhone as PhoneLight } from "@fortawesome/pro-light-svg-icons";
import { faPiggyBank as PiggyBankLight } from "@fortawesome/pro-light-svg-icons";
import { faPlug as PlugLight } from "@fortawesome/pro-light-svg-icons";
import { faPlusCircle as PlusCircleLight } from "@fortawesome/pro-light-svg-icons";
import { faReceipt as ReceiptLight } from "@fortawesome/pro-light-svg-icons";
import { faFileChartLine as ReportLight } from "@fortawesome/pro-light-svg-icons";
import { faBarcodeRead as Scan } from "@fortawesome/pro-light-svg-icons";
import { faSearch as SearchLight } from "@fortawesome/pro-light-svg-icons";
import { faChair as SeatingLight } from "@fortawesome/pro-light-svg-icons";
import { faShare as ShareLight } from "@fortawesome/pro-light-svg-icons";
import { faSortAlt as SortByLight } from "@fortawesome/pro-light-svg-icons";
import { faUsersCrown as TeamLight } from "@fortawesome/pro-light-svg-icons";
import { faUnlock as UnlockLight } from "@fortawesome/pro-light-svg-icons";
import { faUpload as UploadLight } from "@fortawesome/pro-light-svg-icons";
import { faUser as UserLight } from "@fortawesome/pro-light-svg-icons";
import { faUsers as UsersLight } from "@fortawesome/pro-light-svg-icons";
import { faLandmark as VenueLight } from "@fortawesome/pro-light-svg-icons";
import { faAnalytics as AnalyticsSolid } from "@fortawesome/pro-solid-svg-icons";
import { faCalendarStar as CalendarStarSolid } from "@fortawesome/pro-solid-svg-icons";
import { faCreditCardFront as CreditCardFront } from "@fortawesome/pro-solid-svg-icons";
import { faUsdSquare as FeeSolid } from "@fortawesome/pro-solid-svg-icons";
import { faKey as KeySolid } from "@fortawesome/pro-solid-svg-icons";
import { faLongArrowRight as LongRightArrow } from "@fortawesome/pro-solid-svg-icons";
import { faQuestionSquare as QuestionSquareSolid } from "@fortawesome/pro-solid-svg-icons";
import { faFileChartLine as ReportSolid } from "@fortawesome/pro-solid-svg-icons";
import { faSortAlt as SortBy } from "@fortawesome/pro-solid-svg-icons";
import { faUsersCrown as TeamSolid } from "@fortawesome/pro-solid-svg-icons";
import { faArrowAltSquareUp as UpgradeSolid } from "@fortawesome/pro-solid-svg-icons";
import { faAngleDown as AngleDownRegular } from "@fortawesome/pro-regular-svg-icons";
import { faUserFriends as AudienceRegular } from "@fortawesome/pro-regular-svg-icons";
import { faBold as BoldRegular } from "@fortawesome/pro-regular-svg-icons";
import { faCalculator as CalculatorRegular } from "@fortawesome/pro-regular-svg-icons";
import { faCheck as CheckRegular } from "@fortawesome/pro-regular-svg-icons";
import { faGlassCheers as Cheers } from "@fortawesome/pro-regular-svg-icons";
import { faClipboardList as Clipboard } from "@fortawesome/pro-regular-svg-icons";
import { faCrown as CrownRegular } from "@fortawesome/pro-regular-svg-icons";
import { faTrash as DeleteRegular } from "@fortawesome/pro-regular-svg-icons";
import { faDollarSign as Dollar } from "@fortawesome/pro-regular-svg-icons";
import { faArrowAltDown as DownArrow } from "@fortawesome/pro-regular-svg-icons";
import { faFileDownload as DownloadReport } from "@fortawesome/pro-regular-svg-icons";
import { faCode as Embed } from "@fortawesome/pro-regular-svg-icons";
import { faUsdSquare as FeeRegular } from "@fortawesome/pro-regular-svg-icons";
import { faFilter as FilterRegular } from "@fortawesome/pro-regular-svg-icons";
import { faFire as FireRegular } from "@fortawesome/pro-regular-svg-icons";
import { faGift as GiftRegular } from "@fortawesome/pro-regular-svg-icons";
import { faGlobe as GlobeLinesRegular } from "@fortawesome/pro-regular-svg-icons";
import { faGlobeAmericas as GlobeRegular } from "@fortawesome/pro-regular-svg-icons";
import { faChartLine as GraphGrowth } from "@fortawesome/pro-regular-svg-icons";
import { faItalic as ItalicRegular } from "@fortawesome/pro-regular-svg-icons";
import { faKey as KeyRegular } from "@fortawesome/pro-regular-svg-icons";
import { faChevronLeft as LeftChevronRegular } from "@fortawesome/pro-regular-svg-icons";
import { faLink as LinkRegular } from "@fortawesome/pro-regular-svg-icons";
import { faListOl as OListRegular } from "@fortawesome/pro-regular-svg-icons";
import { faPlus as PlusRegular } from "@fortawesome/pro-regular-svg-icons";
import { faPrint as PrintRegular } from "@fortawesome/pro-regular-svg-icons";
import { faFileChartLine as ReportRegular } from "@fortawesome/pro-regular-svg-icons";
import { faChevronRight as RightChevronRegular } from "@fortawesome/pro-regular-svg-icons";
import { faSearch as SearchRegular } from "@fortawesome/pro-regular-svg-icons";
import { faCog as SettingsRegular } from "@fortawesome/pro-regular-svg-icons";
import { faSignOut as SignOut } from "@fortawesome/pro-regular-svg-icons";
import { faStream as StreamRegular } from "@fortawesome/pro-regular-svg-icons";
import { faSync as SyncRegular } from "@fortawesome/pro-regular-svg-icons";
import { faTicketAlt as TicketRegular } from "@fortawesome/pro-regular-svg-icons";
import { faListUl as UListRegular } from "@fortawesome/pro-regular-svg-icons";
import { faUnderline as UnderlineRegular } from "@fortawesome/pro-regular-svg-icons";
import { faUnlock as UnlockRegular } from "@fortawesome/pro-regular-svg-icons";
import { faArrowAltUp as UpArrow } from "@fortawesome/pro-regular-svg-icons";
import { faMegaphone as Update } from "@fortawesome/pro-regular-svg-icons";
import { faArrowAltSquareUp as UpgradeRegular } from "@fortawesome/pro-regular-svg-icons";
import { faUsers as UsersRegular } from "@fortawesome/pro-regular-svg-icons";
import { faAngleDown as AngleDownSolid } from "@fortawesome/free-solid-svg-icons";
import { faAngleUp as AngleUpSolid } from "@fortawesome/free-solid-svg-icons";
import { faUserFriends as AudienceSolid } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft as BackArrow } from "@fortawesome/free-solid-svg-icons";
import { faCashRegister as BoxOfficeSolid } from "@fortawesome/free-solid-svg-icons";
import { faBullhorn as BullhornSolid } from "@fortawesome/free-solid-svg-icons";
import { faCalculator as CalculatorSolid } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDay as CalendarDaySolid } from "@fortawesome/free-solid-svg-icons";
import { faCalendarWeek as CalendarWeekSolid } from "@fortawesome/free-solid-svg-icons";
import { faTimes as Cancel } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle as CancelCircle } from "@fortawesome/free-solid-svg-icons";
import { faCaretDown as CaretDown } from "@fortawesome/free-solid-svg-icons";
import { faMoneyBill as Cash } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle as CheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faUserTie as ContactSolid } from "@fortawesome/free-solid-svg-icons";
import { faCopy as CopySolid } from "@fortawesome/free-solid-svg-icons";
import { faCreditCard as CreditCardBack } from "@fortawesome/free-solid-svg-icons";
import { faCrown as CrownSolid } from "@fortawesome/free-solid-svg-icons";
import { faICursor as Cursor } from "@fortawesome/free-solid-svg-icons";
import { faTrash as DeleteSolid } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope as EnvelopeSolid } from "@fortawesome/free-solid-svg-icons";
import { faEye as EyeSolid } from "@fortawesome/free-solid-svg-icons";
import { faGlobe as GlobeLinesSolid } from "@fortawesome/free-solid-svg-icons";
import { faGlobeAmericas as GlobeSolid } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle as HelpSolid } from "@fortawesome/free-solid-svg-icons";
import { faHome as HomeSolid } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle as infoRegular } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle as InfotipSolid } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft as LeftArrowSolid } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft as LeftChevronSolid } from "@fortawesome/free-solid-svg-icons";
import { faLock as LockSolid } from "@fortawesome/free-solid-svg-icons";
import { faBars as Menu } from "@fortawesome/free-solid-svg-icons";
import { faChartBar as MetricsSolid } from "@fortawesome/free-solid-svg-icons";
import { faMicrophoneAlt as MicrophoneSolid } from "@fortawesome/free-solid-svg-icons";
import { faMobileAlt as MobileSolid } from "@fortawesome/free-solid-svg-icons";
import { faBuilding as OrganizationSolid } from "@fortawesome/free-solid-svg-icons";
import { faPiggyBank as PiggyBankSolid } from "@fortawesome/free-solid-svg-icons";
import { faPlug as PlugSolid } from "@fortawesome/free-solid-svg-icons";
import { faPlus as Plus } from "@fortawesome/free-solid-svg-icons";
import { faPlusCircle as PlusCircle } from "@fortawesome/free-solid-svg-icons";
import { faPrint as PrintSolid } from "@fortawesome/free-solid-svg-icons";
import { faReceipt as ReceiptSolid } from "@fortawesome/free-solid-svg-icons";
import { faChevronCircleRight as RightChevronCircle } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight as RightChevronSolid } from "@fortawesome/free-solid-svg-icons";
import { faSearch as SearchSolid } from "@fortawesome/free-solid-svg-icons";
import { faChair as SeatingSolid } from "@fortawesome/free-solid-svg-icons";
import { faCog as SettingsSolid } from "@fortawesome/free-solid-svg-icons";
import { faShare as ShareSolid } from "@fortawesome/free-solid-svg-icons";
import { faSort as Sort } from "@fortawesome/free-solid-svg-icons";
import { faStar as StarSolid } from "@fortawesome/free-solid-svg-icons";
import { faThumbsDown as ThumbsDownSolid } from "@fortawesome/free-solid-svg-icons";
import { faThumbsUp as ThumbsUpSolid } from "@fortawesome/free-solid-svg-icons";
import { faTicketAlt as TicketSolid } from "@fortawesome/free-solid-svg-icons";
import { faUpload as UploadSolid } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle as UserCircle } from "@fortawesome/free-solid-svg-icons";
import { faUser as UserSolid } from "@fortawesome/free-solid-svg-icons";
import { faUsers as UsersSolid } from "@fortawesome/free-solid-svg-icons";
import { faLandmark as VenueSolid } from "@fortawesome/free-solid-svg-icons";
import { faExclamationTriangle as Warning } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt as CalendarRegular } from "@fortawesome/free-regular-svg-icons";
import { faClock as Clock } from "@fortawesome/free-regular-svg-icons";
import { faCopy as CopyRegular } from "@fortawesome/free-regular-svg-icons";
import { faEdit as Edit } from "@fortawesome/free-regular-svg-icons";
import { faEye as EyeRegular } from "@fortawesome/free-regular-svg-icons";
import { faEyeSlash as EyeSlashRegular } from "@fortawesome/free-regular-svg-icons";
import { faLifeRing as Help } from "@fortawesome/free-regular-svg-icons";
import { faSadTear as SadTear } from "@fortawesome/free-regular-svg-icons";
import { faUser as UserRegular } from "@fortawesome/free-regular-svg-icons";
import { faChartLine as ChartLine } from "@fortawesome/free-solid-svg-icons";
import { faHandHoldingHeart as HandHoldingHeart } from "@fortawesome/pro-regular-svg-icons";

export const Icons = {
  Amex,
  Discover,
  Mastercard,
  Visa,
  AnalyticsLight,
  BellLight,
  BoxOfficeLight,
  BullHornLight,
  CalculatorLight,
  CalendarDayLight,
  CalendarLight,
  CalendarStarLight,
  CancelLight,
  CheckLight,
  CopyLight,
  EditLight,
  EnvelopeLight,
  EnvelopeOpenRegular,
  ExportLight,
  EyeLight,
  FilterLight,
  GiftLight,
  GlobeLinesLight,
  HelpLight,
  HomeLight,
  LinkLight,
  Lock,
  MapPinLight,
  MetricsLight,
  MicrophoneLight,
  MinusCircleLight,
  MobileLight,
  OrganizationLight,
  PhoneLight,
  PiggyBankLight,
  PlugLight,
  PlusCircleLight,
  ReceiptLight,
  ReportLight,
  Scan,
  SearchLight,
  SeatingLight,
  ShareLight,
  SortByLight,
  TeamLight,
  UnlockLight,
  UploadLight,
  UserLight,
  UsersLight,
  VenueLight,
  AnalyticsSolid,
  CalendarStarSolid,
  CreditCardFront,
  FeeSolid,
  KeySolid,
  LongRightArrow,
  QuestionSquareSolid,
  ReportSolid,
  SortBy,
  TeamSolid,
  UpgradeSolid,
  AngleDownRegular,
  AudienceRegular,
  BoldRegular,
  CalculatorRegular,
  CheckRegular,
  Cheers,
  Clipboard,
  CrownRegular,
  DeleteRegular,
  Dollar,
  DownArrow,
  DownloadReport,
  Embed,
  FeeRegular,
  FilterRegular,
  FireRegular,
  GiftRegular,
  GlobeLinesRegular,
  GlobeRegular,
  GraphGrowth,
  ItalicRegular,
  KeyRegular,
  LeftChevronRegular,
  LinkRegular,
  OListRegular,
  PlusRegular,
  PrintRegular,
  ReportRegular,
  RightChevronRegular,
  SearchRegular,
  SettingsRegular,
  SignOut,
  StreamRegular,
  SyncRegular,
  TicketRegular,
  UListRegular,
  UnderlineRegular,
  UnlockRegular,
  UpArrow,
  Update,
  UpgradeRegular,
  UsersRegular,
  AngleDownSolid,
  AngleUpSolid,
  AudienceSolid,
  BackArrow,
  BoxOfficeSolid,
  BullhornSolid,
  CalculatorSolid,
  CalendarDaySolid,
  CalendarWeekSolid,
  Cancel,
  CancelCircle,
  CaretDown,
  Cash,
  CheckCircle,
  ContactSolid,
  CopySolid,
  CreditCardBack,
  CrownSolid,
  Cursor,
  DeleteSolid,
  EnvelopeSolid,
  EyeSolid,
  GlobeLinesSolid,
  GlobeSolid,
  HelpSolid,
  HomeSolid,
  InfotipSolid,
  LeftArrowSolid,
  LeftChevronSolid,
  LockSolid,
  Menu,
  MetricsSolid,
  MicrophoneSolid,
  MobileSolid,
  OrganizationSolid,
  PiggyBankSolid,
  PlugSolid,
  Plus,
  PlusCircle,
  PrintSolid,
  ReceiptSolid,
  RightChevronCircle,
  RightChevronSolid,
  SearchSolid,
  SeatingSolid,
  SettingsSolid,
  ShareSolid,
  Sort,
  StarSolid,
  ThumbsDownSolid,
  ThumbsUpSolid,
  TicketSolid,
  UploadSolid,
  UserCircle,
  UserSolid,
  UsersSolid,
  VenueSolid,
  Warning,
  CalendarRegular,
  Clock,
  CopyRegular,
  Edit,
  EyeRegular,
  EyeSlashRegular,
  Help,
  SadTear,
  UserRegular,
  infoRegular,
  ChartLine,
  HandHoldingHeart
};
 
const Container = styled.div<any>`
  color: ${(props) => props.color};

  &:hover {
    color: ${(props) => {
      if (props.color === "inherit") return null;
      return props.hoverColor;
    }};
  }
`;

export type IconProps = {
  icon: any;
  color?: Colors | string | null;
  hoverColor?: Colors | string | null;
  onClick?: any | null;
  size?: string | number | undefined;
  position?: string;
  top?: string | number | undefined;
  left?: string | number | undefined;
  right?: string | number | undefined;
  zIndex?: number | void;
  margin?: string | number | undefined;
  tip?: string;
  transitionDuration?: string;
  rotation?: number;
};

export default function Icon({
  icon = Icons.AudienceRegular,
  color = Colors.Pink,
  hoverColor = null,
  onClick,
  size = 20,
  top,
  left,
  right,
  position = "relative" as any,
  zIndex,
  margin,
  tip,
  transitionDuration,
  rotation,
}: IconProps) {
  const cursor: string = onClick ? "pointer" : "";

  return (
    <Container color={color} hoverColor={hoverColor} data-tip={tip}>
      <FontAwesomeIcon
        icon={icon as any}
        onClick={onClick}
        style={{
          top,
          left,
          right,
          position: position as any,
          zIndex: zIndex as any,
          fontSize: size,
          transition: `all ${transitionDuration || "0.2s"}`,
          transform: rotation ? `rotate(${rotation}deg)` : undefined,
          margin,
          cursor,
        }}
      />
    </Container>
  );
}
