import {
  AppActionTypes,
  AppActionCreatorTypes,
  SetDonationPortalStatusAction,
  SetScreenAction,
  SetOrganizationIdAction,
  CacheOrganizationsAction,
  SetErrorAction,
  CreateStripeTokenAction,
  CreateStripeTokenSuccessAction,
  CreateStripeTokenFailureAction,
  SetCreateDonationParamsAction,
  CreateDonationAction,
  CreateDonationSuccessAction,
  CreateDonationFailureAction,
  ToggleShareModalAction
} from '../actions/app.actions';
import { DonationPortalStatusEnum } from '@matchstik/models/.dist/enums/DonationPortalStatusEnum';
import IOrganization from "@matchstik/models/.dist/interfaces/IOrganization";
import * as UrlUtil from '@matchstik/utils/.dist/UrlUtil';
import * as ReduxUtil from '@matchstik/utils/.dist/ReduxUtil';
import UrlParams from '../../models/interfaces/UrlParams';
import ICreateDonationParams, { createDonationParamsState } from "@matchstik/models/.dist/interfaces/ICreateDonationParams";

export enum ErrorKeyEnum {
  Global = 'Global',
  UserEmail = 'UserEmail',
  ConfirmUserEmail = 'ConfirmUserEmail',
  UserFullName = 'UserFullName',
  UserPhoneNumber = 'UserPhoneNumber',
}

export enum ScreenEnum {
  Campaign= 'Campaign',
  Donate = 'Donate',
  Email = 'Email',
  DonationType = 'DonationType',
  DonorType = 'DonorType',
  UserInfo = 'UserInfo',
  BusinessInfo = 'BusinessInfo',
  UserAddressInfo = 'UserAddressInfo',
  BusinessAddressInfo = 'BusinessAddressInfo',
  EnterPayment = 'EnterPayment',
  ConfirmDonation = 'Confirm Donation',
  DonationConfirmed = 'Donation Confirmed',
  ThankYou = 'ThankYou',
}

export interface IOrganizationCache {
  [organizationId: string]: IOrganization;
}

export type ErrorMap = {
  [key in ErrorKeyEnum]?: string
}

type AppReducerState = {
  status: DonationPortalStatusEnum;
  intercomEnabled: boolean;
  screen: ScreenEnum;
  organizationId: string;
  organizationsCache: IOrganizationCache;
  errors: ErrorMap;
  createDonationParams: ICreateDonationParams
  loading: boolean;
  shareModal: boolean;
  donorId?: string
};

function appReducerState(): AppReducerState {
  const { query } = UrlUtil.parse(window.location.toString());
  const {
    organizationId = "",
    donorId = ""
  }: UrlParams = query;

  return {
    status: DonationPortalStatusEnum.Initializing,
    intercomEnabled: false,
    screen: ScreenEnum.Campaign,
    organizationId,
    organizationsCache: {},
    errors: {},
    createDonationParams: createDonationParamsState(),
    loading: false,
    shareModal: false,
    donorId
  };
}

export default function reducer(state = appReducerState(), action: AppActionCreatorTypes) {
  const { type, payload } = action;

  switch (type) {
    case AppActionTypes.SET_PURCHASE_PORTAL_STATUS:
      return setPurchasPortalStatus(state, payload as SetDonationPortalStatusAction["payload"]);

    case AppActionTypes.SET_SCREEN:
      return setScreen(state, payload as SetScreenAction["payload"]);

    /********************************************************************************
    *  Organization
    *******************************************************************************/

    case AppActionTypes.SET_ORGANIZATION_ID:
      return setOrganizationId(state, payload as SetOrganizationIdAction["payload"]);

    case AppActionTypes.CACHE_ORGANIZATIONS:
      return cacheOrganizations(state, payload as CacheOrganizationsAction["payload"]);

    /********************************************************************************
    *  Errors
    *******************************************************************************/

    case AppActionTypes.SET_ERROR:
      return setError(state, payload as SetErrorAction["payload"]);

    case AppActionTypes.SET_ERROR_EMPTY:
      return setErrorEmpty(state);

  /********************************************************************************
  *  Create Stripe Token
  *******************************************************************************/

    case AppActionTypes.CREATE_STRIPE_TOKEN:
      return createStripeToken(state);
    case AppActionTypes.CREATE_STRIPE_TOKEN_SUCCESS:
      return createStripeTokenSuccess(state, payload as CreateStripeTokenSuccessAction["payload"]);
    case AppActionTypes.CREATE_STRIPE_TOKEN_FAILURE:
      return createStripeTokenFailure(state, payload as CreateStripeTokenFailureAction["payload"]);

    /********************************************************************************
    *  Create Donation
    *******************************************************************************/

    case AppActionTypes.SET_CREATE_DONATION_PARAMS:
      return setCreateDonationParams(state, payload as SetCreateDonationParamsAction["payload"]);

    case AppActionTypes.CREATE_DONATION:
      return createDonation(state);
    case AppActionTypes.CREATE_DONATION_SUCCESS:
      return createDonationSuccess(state,payload as {organizationId:string});
    case AppActionTypes.CREATE_DONATION_FAILURE:
      return createDonationFailure(state, payload as CreateDonationFailureAction["payload"]);

    case AppActionTypes.TOGGLE_SHARE_MODAL:
      return toggleShareModal(state, payload as ToggleShareModalAction["payload"]);

    default:
      return state;
  }
}

/********************************************************************************
 *  Set Status
 *******************************************************************************/

function setPurchasPortalStatus(
  state: AppReducerState,
  payload: {
    status: DonationPortalStatusEnum,
  }
): AppReducerState {
  return {
    ...state,
    status: payload.status,
  };    
};

/********************************************************************************
 *  Set Screen
 *******************************************************************************/

function setScreen(
  state: AppReducerState,
  { screen }: { screen: ScreenEnum }
): AppReducerState {
  return {
    ...state,
    screen,
  };
};

/********************************************************************************
 *  Set Organization ID
 *******************************************************************************/

function setOrganizationId(
  state: AppReducerState,
  { organizationId, replace = false }: { organizationId: string; replace?: boolean }
): AppReducerState {
  // UrlUtil.setQueryString({ organizationId }, replace);

  return {
    ...state,
    organizationId,
  };
}

/********************************************************************************
 *  Cache Organizations
 *******************************************************************************/

function cacheOrganizations(
  state: AppReducerState,
  { organizations }: { organizations: IOrganization[] }
): AppReducerState {
  return {
    ...state,
    organizationsCache: ReduxUtil.makeCache(organizations, "_id", state.organizationsCache),
  };
}

/********************************************************************************
 *  Set Error
 *******************************************************************************/

function setError(
  state: AppReducerState,
  { key, errorMsg }: { key: ErrorKeyEnum, errorMsg: string }
): AppReducerState {
  return {
    ...state,
    errors: {
      ...state.errors,
      [key]: errorMsg,
    }
  };
}

/********************************************************************************
 *  Set Error
 *******************************************************************************/

function setErrorEmpty(state: AppReducerState): AppReducerState {
  return {
    ...state,
    errors: {}
  };
}

/********************************************************************************
 *  Create Stripe Token
 *******************************************************************************/

function createStripeToken(
  state: AppReducerState,
): AppReducerState {

  state = setError(state, {key: ErrorKeyEnum.Global, errorMsg: ''});
  state = setCreateDonationParams(state, { params: { stripeToken: '' } });

  return {
    ...state,
    loading: true,
  };
}

function createStripeTokenSuccess(
  state: AppReducerState,
  { stripeToken, }: { stripeToken: string }
): AppReducerState {

  state = setError(state, { key: ErrorKeyEnum.Global, errorMsg: '' });
  state = setCreateDonationParams(state, { params: { stripeToken } });

  return {
    ...state,
    loading: false,
  };
}

function createStripeTokenFailure(
  state: AppReducerState,
  { errorMsg, }: { errorMsg: string }
): AppReducerState {

  state = setError(state, { key: ErrorKeyEnum.Global, errorMsg: errorMsg });
  state = setCreateDonationParams(state, { params: { stripeToken: '' } });

  return {
    ...state,
    loading: false,
  };
}

/********************************************************************************
 *  Create Donation
 *******************************************************************************/

function setCreateDonationParams(
  state: AppReducerState,
  { params, }: { params: Partial<ICreateDonationParams> }
): AppReducerState {
  return {
    ...state,
    createDonationParams: {
      ...state.createDonationParams,
      ...params,
    }
  };
}

function createDonation(
  state: AppReducerState,
): AppReducerState {

  state = setError(state, { key: ErrorKeyEnum.Global, errorMsg: '' });

  return {
    ...state,
    loading: true,
  };
}

function createDonationSuccess(
  state: AppReducerState,{organizationId }:{organizationId:string}
): AppReducerState {

  state = setError(state, { key: ErrorKeyEnum.Global, errorMsg: '' });
  state = setCreateDonationParams(state, { params: {...createDonationParamsState(),organizationId} });

  return {
    ...state,
    loading: false,
  };
}

function createDonationFailure(
  state: AppReducerState,
  { errorMsg, }: { errorMsg: string }
): AppReducerState {

  state = setError(state, { key: ErrorKeyEnum.Global, errorMsg: errorMsg });

  return {
    ...state,
    loading: false,
  };
}


function toggleShareModal(
    state: AppReducerState,
    { shareModal, }: { shareModal: boolean }
  ): AppReducerState {

    return {
      ...state,
      shareModal
    };
}
