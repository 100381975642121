import client from "../graphql/client";

const MATCHSTIK_AUTH_TOKEN = "MATCHSTIK_AUTH_TOKEN";

export async function setToken(token: string) {
  await localStorage.clear();
  return await localStorage.setItem(MATCHSTIK_AUTH_TOKEN, token);
}

export async function getToken() {
  return await localStorage.getItem(MATCHSTIK_AUTH_TOKEN);
}

export async function logout() {
  localStorage.clear();
  client.resetStore();
  window.location.href = '/';
}
