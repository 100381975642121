import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { DonationPortalState } from "../redux/store";
import IOrganization from "@matchstik/models/.dist/interfaces/IOrganization";
import { Colors } from "../styles/Colors";
import ScreenHeader from "../components/ScreenHeader";
import ICreateDonationParams from "@matchstik/models/.dist/interfaces/ICreateDonationParams";
import * as AppActions from "../redux/actions/app.actions";
import TextArea from "../elements/TextArea";
import { DonationTypesEnum } from '@matchstik/models/.dist/enums/DonationTypesEnum';
import ErrorText  from "../elements/ErrorText";
import { ErrorKeyEnum } from "../redux/reducers/app.reducer";
import useShowNext from "../hooks/useShowNext.hook";
import ReCAPTCHA from "react-google-recaptcha";
import { FadeIn } from "../elements/Motion";

type ContainerProps = {
  showNext: boolean;
}
const Container = styled.div<ContainerProps>`
  position: relative;
  margin-top: -50px;
  background-color: ${Colors.White};
  border-radius: 15px;
  overflow: hidden;
  height: calc(100% - ${props => props.showNext ?"335px": "275px"});
  @media screen and (max-device-width: 991px) and (orientation: landscape){
    overflow:initial;
    height:auto;
   }
`;

const Content = styled.div`
  padding: 0 30px;
  overflow: auto;
  overflow-x: hidden;
  padding-top: 12px;
  padding-bottom: 12px;
  height: calc(100% - 84px);
  @media screen and (max-device-width: 991px) and (orientation: landscape){
    overflow: initial;
    overflow-x: initial;
   }
`;
const BigText = styled.div`
  font-size: 1.4rem;
  font-weight: 800;
  color: ${Colors.Grey1};
`;

const SmallText = styled.div`
  font-size: 1.2rem;
  font-weight: 800;
  color: ${Colors.Grey2};
  margin: 15px 0px;
`;
const ReCAPTCHAContainer = styled(FadeIn)`
text-align: center;
margin: auto;
padding-left: 10px;
padding-bottom: 6px;
padding-top: 2px;
`;

const NewErrorText = styled(ErrorText)`
  color: #EE5050;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  position: relative;
  background: #ffff;
  padding: 15px;
  width: calc(100% - 30px);
  word-break: break-word;
  border: 2px solid #ddd;
  text-align: left;
  border-radius: 10px;
`;

type ConfirmDonationProps = {
  organization: IOrganization;
};

const ConfirmDonation: React.FC<ConfirmDonationProps> = ({ organization }) => {
  /** State **/
  const { app } = useSelector((state: DonationPortalState) => state);
  const {
    errors,
    createDonationParams: { amount, message, type, donationAmount, allowFeedDisplay },
  } = app;
 const error = errors[ErrorKeyEnum.Global];
 const showNext = useShowNext();
 const confirmDonation = useRef<any>(null);
 const scrollToBottom = () => {
   if(confirmDonation?.current){
    confirmDonation.current.scrollTop= confirmDonation.current.scrollHeight
   }
}

useEffect(() => {
  if(error){
    setTimeout(() => {
    scrollToBottom()
    },0);
  }
}, [error]);


  /** Actions **/
  const dispatch = useDispatch();
  const setError = (key: ErrorKeyEnum, errorMsg: string) =>
    dispatch(AppActions.setError(key, errorMsg));
  const setCreateDonationParams = (params: Partial<ICreateDonationParams>) =>
    dispatch(AppActions.setCreateDonationParams(params));
    const setCaptcha = (c: any) => {
      dispatch(AppActions.setCreateDonationParams({ cToken: c }));
      setError(ErrorKeyEnum.Global, "");

      // navigateForward();
    }
  /** Render **/
  const isMembership = type === DonationTypesEnum.FirstMembershipDonation;
  return (
    <Container showNext={showNext}>
      <ScreenHeader title="Confirm Donation" />
      <Content ref={confirmDonation}>
        <BigText>
          Are you ready to confirm your donation of ${Number(donationAmount).toFixed(2)} to{" "}
          {organization.name}?
        </BigText>
        {isMembership ? (
          <SmallText>
            {`As a recurring donor, this exact same donation will repeat every 30 days and you will receive an email
            confirming the donation. Please contact ${organization.name} if you wish to cancel your membership.`}
          </SmallText>
        ) : (
          <SmallText>
            You will receive an email with details of this transaction.
          </SmallText>
        )}
        <TextArea
          height={isMembership ? '40px' : '75px'}
          placeholder="Leave an optional note with your donation"
          value={message}
          onChange={(event: React.FormEvent<HTMLInputElement>) => {
            const message = event.currentTarget.value;
            setCreateDonationParams({ message });
          }}
          />
          {
            organization.stripeConnectId !== null && (
              <ReCAPTCHAContainer>
                <ReCAPTCHA
                  sitekey="6LfsW-QmAAAAAMl0Z8qTiYuMYo1hOLzGs1rP-74L"
                  onChange={setCaptcha}
                />
              </ReCAPTCHAContainer>
            )
          }
         {error && <NewErrorText align="center">{error}</NewErrorText>}
      </Content>
    </Container>
  );
};

export default ConfirmDonation;
