import Joi from "@hapi/joi";

export const email = Joi.object({
    email: Joi.string().email({ tlds: { allow: false } }).required().error(([error]) => {
        const message = "Email is invalid";
        return new Error(
            JSON.stringify({
                field: error.path[0],
                message
            })
        );
    }),
});

export const validateBusiness = (donations: any) => {
    let donationBusinessSchema;
    donationBusinessSchema = Joi.object()
        .options({ abortEarly: false })
        .keys({
            name: Joi.string()
                .required()
                .messages({ "string.empty": 'Business Name is a required field' }),
            url: Joi.string()
                .optional()
                .allow("")
                .required()
                .uri({ allowRelative: false })
                .messages({ "string.uri": 'Please Enter Valid url' })
        })
        .unknown(true);
    return donationBusinessSchema.validate(donations);
}

export const validateIndividual = (donation: any) => {
    const phoneNumberWithCode = donation?.phoneNumber.split("")[0] === '1'
    let donationSchema;
    donationSchema = Joi.object()
        .options({ abortEarly: false })
        .keys({
            name: Joi.string()
                .required()
                .messages({ "string.empty": 'Name is a required field' }),
            phoneNumber: Joi.string().min(phoneNumberWithCode ? 11 : 10).max(11).required()
                .messages({ "string.empty": 'Phone Number is a required field', "string.min": 'Phone Number should have minimum length of {#limit}',"string.max": 'Phone Number should have maximun length of {#limit}' }),
        })
        .unknown(true);
    return donationSchema.validate(donation);
}

export const validateAddresUser = (donation: any) => {
    if (!donation.state) {
        donation.state = ''
    }
    let AddressIndSchema;
    AddressIndSchema = Joi.object()
        .options({ abortEarly: false })
        .keys({
            streetAddress: Joi.string().required()
                .messages({ "string.empty": 'Street address is a required field' }),
            city: Joi.string().required()
                .messages({ "string.empty": 'City is a required field' }),
            state: Joi.string().required()
                .messages({ "string.empty": 'State is a required field' }),
            zip: Joi.string().required()
                .messages({ "string.empty": 'Zip is a required field' }),
        })
        .unknown(true);
    return AddressIndSchema.validate(donation);
}

export const validateAddresBusiness = (donation: any) => {
    if (!donation.state) {
        donation.state = ''
    }
    let AddressBusSchema;
    AddressBusSchema = Joi.object()
        .options({ abortEarly: false })
        .keys({
            streetAddress: Joi.string().required()
                .messages({ "string.empty": 'Street address is a required field' }),
            city: Joi.string().required()
                .messages({ "string.empty": 'City is a required field' }),
            state: Joi.string().required()
                .messages({ "string.empty": 'State is a required field' }),
            zip: Joi.number().required()
                .messages({ "string.empty": 'Zip is a required field' }),
        })
        .unknown(true);
    return AddressBusSchema.validate(donation);
}
