import gql from 'graphql-tag';

const query = gql`
query listCampaign($organizationId:String){
  listCampaign(organizationId:$organizationId) {
      _id
      name
      goal
      startDate
      endDate
      description
      posterUrl
      totalMatchers
      meta {
        createdAt
      },
      totalDonationAmount,
      donors,
      completed
      isDefault
      active
      isComplete
    }
  }
`;

export default query;
