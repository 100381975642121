import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { useSelector, useDispatch } from 'react-redux';
import { DonationPortalState } from "../redux/store";
import IOrganization from "@matchstik/models/.dist/interfaces/IOrganization";
import { Colors } from "../styles/Colors";
import Icon, { Icons } from "../elements/Icon";
import ScreenHeader from '../components/ScreenHeader';
import Input from '../elements/Input';
import ICreateDonationParams from "@matchstik/models/.dist/interfaces/ICreateDonationParams";
import * as AppActions from '../redux/actions/app.actions';
import { ErrorKeyEnum } from '../redux/reducers/app.reducer';
import Label from '../elements/Label';
import LabeledInput from '../elements/LabeledInput';
import useShowNext from "../hooks/useShowNext.hook";
type ContainerProps = {
  showNext: boolean;
}
const Container = styled.div<ContainerProps>`
  position: relative;
  margin-top: -50px;
  background-color: ${Colors.White};
  border-radius: 15px;
  overflow: hidden;
  height: calc(100% - ${props => props.showNext ?"335px": "275px"});
  @media screen and (max-device-width: 991px) and (orientation: landscape){
    overflow:initial;
    height:auto;
   }
`;

const Content = styled.div`
  padding: 0 30px;
  overflow: auto;
  overflow-x: hidden;
  padding-top: 12px;
  padding-bottom: 12px;
  height: calc(100% - 84px);
  @media screen and (max-device-width: 991px) and (orientation: landscape){
    overflow: initial;
    overflow-x: initial;
   }
`;

type EmailProps = {
  organization: IOrganization;
};


const Email: React.FC<EmailProps> = ({ organization }) => {
  /** State **/
  const { app } = useSelector((state: DonationPortalState) => state);
  const { createDonationParams: { email } } = app;
  const errors: any = app.errors
  const showNext = useShowNext();

  /** Actions **/
  const dispatch = useDispatch();
  const  setCreateDonationParams = (params: Partial<ICreateDonationParams>) =>
    dispatch(AppActions.setCreateDonationParams(params))

  /** GraphQL **/

  /** Render **/
  return (
    <Container showNext={showNext}>
      <ScreenHeader title="Contact Info" />
      <Content>
        <Label text="Can we get your email address?"/>
        <LabeledInput
          autoFocus
          type="email"
          // label="Full Name"
          placeholder="Your email address"
          // size={InputSizes.Large}
          value={email}
          width="100%"
          onChange={(event: React.FormEvent<HTMLInputElement>) => {
            const value = event.currentTarget.value;
            setCreateDonationParams({ email: value });
          }}
          margin="0px 0px 10px 0px"
          error ={errors["email"]}
        />
      </Content>
    </Container>
  );
};

export default Email;
