import { DonationPortalStatusEnum } from '@matchstik/models/.dist/enums/DonationPortalStatusEnum';
import IOrganization from "@matchstik/models/.dist/interfaces/IOrganization";
import ICreateDonationParams from "@matchstik/models/.dist/interfaces/ICreateDonationParams";
import { ScreenEnum, ErrorKeyEnum } from '../reducers/app.reducer';


export const AppActionTypes = {
  // Status
  SET_PURCHASE_PORTAL_STATUS: 'SET_PURCHASE_PORTAL_STATUS',
  // Screen
  SET_SCREEN: 'SET_SCREEN',
  NAVIGATE_FORWARD: 'NAVIGATE_FORWARD',
  NAVIGATE_BACKWARD: 'NAVIGATE_BACKWARD',
  // Organizations
  SET_ORGANIZATION_ID: "SET_ORGANIZATION_ID",
  CACHE_ORGANIZATIONS: "CACHE_ORGANIZATIONS",
  // Errors
  SET_ERROR: 'SET_ERROR',
  SET_ERROR_EMPTY: 'SET_ERROR_EMPTY',
  // Create Stripe Token
  CREATE_STRIPE_TOKEN: 'CREATE_STRIPE_TOKEN',
  CREATE_STRIPE_TOKEN_SUCCESS: 'CREATE_STRIPE_TOKEN_SUCCESS',
  CREATE_STRIPE_TOKEN_FAILURE: 'CREATE_STRIPE_TOKEN_FAILURE',
  // Create Donation
  SET_CREATE_DONATION_PARAMS: 'SET_CREATE_DONATION_PARAMS',
  CREATE_DONATION: 'CREATE_DONATION',
  CREATE_DONATION_SUCCESS: 'CREATE_DONATION_SUCCESS',
  CREATE_DONATION_FAILURE: 'CREATE_DONATION_FAILURE',

  TOGGLE_SHARE_MODAL: 'TOGGLE_SHARE_MODAL'
};

/********************************************************************************
 *  App Action Creators
 *******************************************************************************/

export type AppActionCreatorTypes =
  SetDonationPortalStatusAction
  | SetScreenAction
  | NavigateForwardAction
  | NavigateBackwardAction
  | SetOrganizationIdAction
  | CacheOrganizationsAction
  | SetErrorAction
  | SetErrorRemoveAction
  | CreateStripeTokenAction
  | CreateStripeTokenSuccessAction
  | CreateStripeTokenFailureAction
  | SetCreateDonationParamsAction
  | CreateDonationAction
  | CreateDonationSuccessAction
  | CreateDonationFailureAction
  | ToggleShareModalAction

/********************************************************************************
*  Set Donation Portal Status
*******************************************************************************/

export interface SetDonationPortalStatusAction {
  type: typeof AppActionTypes.SET_PURCHASE_PORTAL_STATUS;
  payload: {
    status: DonationPortalStatusEnum,
  };
};

export function seDonationPortalStatus(status: DonationPortalStatusEnum ): SetDonationPortalStatusAction {
  return {
    type: AppActionTypes.SET_PURCHASE_PORTAL_STATUS,
    payload: {
      status,
    },
  };
};

/********************************************************************************
*  Set Screen
*******************************************************************************/

export interface SetScreenAction {
  type: typeof AppActionTypes.SET_SCREEN;
  payload: {
    screen: ScreenEnum,
  };
};

export function setScreen(screen: ScreenEnum): SetScreenAction {
  return {
    type: AppActionTypes.SET_SCREEN,
    payload: {
      screen,
    },
  };
};

/********************************************************************************
*  Navigate Forward
*******************************************************************************/

export interface NavigateForwardAction {
  type: typeof AppActionTypes.NAVIGATE_FORWARD;
  payload: {};
};

export function navigateForward(): NavigateForwardAction {
  return {
    type: AppActionTypes.NAVIGATE_FORWARD,
    payload: {},
  };
};

/********************************************************************************
*  Navigate Backward
*******************************************************************************/

export interface NavigateBackwardAction {
  type: typeof AppActionTypes.NAVIGATE_BACKWARD;
  payload: {};
};

export function navigateBackward(): NavigateBackwardAction {
  return {
    type: AppActionTypes.NAVIGATE_BACKWARD,
    payload: {},
  };
};

/********************************************************************************
 *  Set Organization ID
 *******************************************************************************/
export interface SetOrganizationIdAction {
  type: typeof AppActionTypes.SET_ORGANIZATION_ID;
  payload: {
    organizationId: string;
    replace: boolean
  };
}

export function setOrganizationId(organizationId: string, replace: boolean = false): SetOrganizationIdAction {
  return {
    type: AppActionTypes.SET_ORGANIZATION_ID,
    payload: {
      organizationId,
      replace,
    },
  };
}

/********************************************************************************
 *  Cache Organizations
 *******************************************************************************/

export interface CacheOrganizationsAction {
  type: typeof AppActionTypes.CACHE_ORGANIZATIONS;
  payload: {
    organizations: IOrganization[];
  };
}

export function cacheOrganizations(organizations: IOrganization[]): CacheOrganizationsAction {
  return {
    type: AppActionTypes.CACHE_ORGANIZATIONS,
    payload: {
      organizations,
    },
  };
}

/********************************************************************************
 *  Set Error
 *******************************************************************************/

export interface SetErrorAction {
  type: typeof AppActionTypes.SET_ERROR;
  payload: {
    key: ErrorKeyEnum;
    errorMsg: string;
  };
}

export function setError(key: ErrorKeyEnum, errorMsg: string): SetErrorAction {
  return {
    type: AppActionTypes.SET_ERROR,
    payload: {
      key,
      errorMsg,
    },
  };
}


/********************************************************************************
 *  Set Error
 *******************************************************************************/

 export interface SetErrorRemoveAction {
  type: typeof AppActionTypes.SET_ERROR;
  payload: {};
}

export function setErrorEmpty(): SetErrorRemoveAction {
  return {
    type: AppActionTypes.SET_ERROR_EMPTY,
    payload: {},
  };
}

/********************************************************************************
 *  Create Stripe Token
 *******************************************************************************/

export interface CreateStripeTokenAction {
  type: typeof AppActionTypes.CREATE_STRIPE_TOKEN;
  payload: {};
}

export function createStripeToken(): CreateStripeTokenAction {
  return {
    type: AppActionTypes.CREATE_STRIPE_TOKEN,
    payload: {},
  };
}


export interface CreateStripeTokenSuccessAction {
  type: typeof AppActionTypes.CREATE_STRIPE_TOKEN_SUCCESS;
  payload: {
    stripeToken: string,
  };
}

export function createStripeTokenSuccess(stripeToken: string): CreateStripeTokenSuccessAction {
  return {
    type: AppActionTypes.CREATE_STRIPE_TOKEN_SUCCESS,
    payload: {
      stripeToken,
    },
  };
}

export interface CreateStripeTokenFailureAction {
  type: typeof AppActionTypes.CREATE_STRIPE_TOKEN_FAILURE;
  payload: {
    errorMsg: string,
  };
}

export function createStripeTokenFailure(errorMsg: string): CreateStripeTokenFailureAction {
  return {
    type: AppActionTypes.CREATE_STRIPE_TOKEN_FAILURE,
    payload: {
      errorMsg,
    },
  };
}

/********************************************************************************
 *  Set Create Donation Params
 *******************************************************************************/

export interface SetCreateDonationParamsAction {
  type: typeof AppActionTypes.SET_CREATE_DONATION_PARAMS;
  payload: {
    params: Partial<ICreateDonationParams>
  };
}

export function setCreateDonationParams(params: Partial<ICreateDonationParams>): SetCreateDonationParamsAction {
  return {
    type: AppActionTypes.SET_CREATE_DONATION_PARAMS,
    payload: {
      params,
    },
  };
}

/********************************************************************************
 *  Create Donationn
 *******************************************************************************/

export interface CreateDonationAction {
  type: typeof AppActionTypes.CREATE_DONATION;
  payload: {};
}

export function createDonation(): CreateDonationAction {
  return {
    type: AppActionTypes.CREATE_DONATION,
    payload: {},
  };
}


export interface CreateDonationSuccessAction {
  type: typeof AppActionTypes.CREATE_DONATION_SUCCESS;
  payload: {};
}

export function createDonationSuccess(params:any): CreateDonationSuccessAction {  
  const organizationId = params.organizationId
  return {
    type: AppActionTypes.CREATE_DONATION_SUCCESS,
    payload: {organizationId},
  };
}


export interface CreateDonationFailureAction {
  type: typeof AppActionTypes.CREATE_DONATION_FAILURE;
  payload: {
    errorMsg: string,
  };
}

export function createDonationFailure(errorMsg: string): CreateDonationFailureAction {
  return {
    type: AppActionTypes.CREATE_DONATION_FAILURE,
    payload: {
      errorMsg,
    },
  };
}


export interface ToggleShareModalAction {
  type: typeof AppActionTypes.TOGGLE_SHARE_MODAL;
  payload: {
      shareModal: boolean
  }
}


export function toggleShareModal(shareModal: boolean): ToggleShareModalAction {
  return {
    type: AppActionTypes.TOGGLE_SHARE_MODAL,
    payload: {
      shareModal,
    },
  };
}
