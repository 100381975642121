import React, { useLayoutEffect } from 'react';
import { DonationPortalStatusEnum } from "@matchstik/models/.dist/enums/DonationPortalStatusEnum";
import styled from 'styled-components';
import { motion } from "framer-motion";
import {useDispatch } from "react-redux";
import { seDonationPortalStatus } from './redux/actions/app.actions';
import DonationPortal from './containers/DonationPortal';
import { STRIPE_PUBLISHABLE_KEY } from './env';
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
} from "@stripe/react-stripe-js";

const Container = styled(motion.div)<any>`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const fonts = [
  {
    cssSrc: "https://use.typekit.net/suf4knx.css",
    family: "neue-haas-grotesk-display",
  },
];

export default function App(props: any) {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(seDonationPortalStatus(DonationPortalStatusEnum.Ready));
  }, []);

  React.useEffect(() => {
    window.addEventListener('message', (event) => {
      const action = event.data;

      // Ensure its a redux action
      if(action?.type) {
        dispatch(action);
      }
      
    });
  }, []);

  function close(event: any) {
    let target = event.target;
    if (!target) return;
    const parents = [];
    while (target) {
      parents.unshift(target.id);
      target = target.parentNode;
    }
    
    if (parents.includes("MATCHSTIK_DONATION_MODAL")) return;

    // Embed.close();
  }

  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

  return (
    <Container
      initial="hidden"
      animate="visible"
      variants={{
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
      }}
      className="App"
      onClick={(event: React.MouseEvent<HTMLElement>) => close(event)}
    >
      <Elements stripe={stripePromise} options={{ fonts }}>
        <DonationPortal {...props} />
      </Elements>
    </Container>
  );
};
