import { useSelector, useDispatch } from "react-redux";
import { DonationPortalState } from "../redux/store";
import { useQuery } from "@apollo/react-hooks";
import * as AppActions from "../redux/actions/app.actions";
import IOrganization from "@matchstik/models/.dist/interfaces/IOrganization";
import { ApolloError } from "apollo-client";
import gql from 'graphql-tag';
import ICreateDonationParams from "@matchstik/models/.dist/interfaces/ICreateDonationParams";

const GET_ORGANIZATION = gql`
  query Organization($organizationId: String){
    organization(organizationId: $organizationId) {
      _id
      userId
      active
      name
      description
      message
      recurringDonationMessage
      phoneNumber
      email
      address
      logoUrl
      posterUrl
      presetDonations
      hasActiveSubscription
      needSubscription
      stripeConnectId
      status
      donorFees
    }
  }
`;

function preloadImages(imageUrls: string[]) {
  imageUrls.forEach(imageUrl => {
    (new Image).src = imageUrl;
  });
}

interface OrganizationData {
  organization: IOrganization;
}

interface OrganizationVars {
  organizationId: string;
}

type UseEvent = {
  organization: IOrganization | undefined;
  organizationId: string;
  loading: boolean;
  error: ApolloError | undefined;
};

type UseEventHook = (organizationId?: string) => UseEvent;
const useEventHook: UseEventHook = (organizationId) => {
  /* State */
  const { organizationId: stateOrganizationId, organizationsCache } = useSelector(
    (state: DonationPortalState) => state.app
  );

  organizationId = (stateOrganizationId || organizationId) as string;

  const organization = organizationsCache[organizationId];


  /* Actions */
  const dispatch = useDispatch();
  const cacheOrganization = (organization: IOrganization) =>
    dispatch(AppActions.cacheOrganizations([organization]));

  const setCreateDonationParams = (params: Partial<ICreateDonationParams>) =>
    dispatch(AppActions.setCreateDonationParams(params));

  /* Hooks */
  const { loading, error } = useQuery<OrganizationData, OrganizationVars>(GET_ORGANIZATION, {
    variables: {
      organizationId,
    },
    onCompleted: (data) => {
      if (data.organization && !organization) {
        cacheOrganization(data.organization);
        setCreateDonationParams({
          organizationId: data.organization._id,
        });
        
        if(data?.organization?.logoUrl) {
          preloadImages([data.organization.logoUrl]);
        }
      }
    },
  });

  return {
    organization: organization,
    organizationId: organizationId,
    loading: organization ? false : loading,
    error: error,
  };
};

export default useEventHook;
