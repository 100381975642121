import { useSelector, useDispatch } from "react-redux";
import { DonationPortalState } from "../redux/store";
import * as AppActions from "../redux/actions/app.actions";
import IOrganization from "@matchstik/models/.dist/interfaces/IOrganization";
import { ScreenEnum, ErrorKeyEnum } from "../redux/reducers/app.reducer";

type UseShowNextHook = () => boolean;


const useShowNextHook: UseShowNextHook = () => {
  /* State */
  const { app } = useSelector(
    (state: DonationPortalState) => state
  );
  // const organization = useSelector((state: any) => state.user.organization);

  const {
    createDonationParams: {
      organizationId,
      donorType,
      type,
      anonymous,
      email,
      name,
      phoneNumber,
      url,
      address,
      donationAmount,
      message,
      campaignId,
    }
  } = app;

  const { errors, organizationsCache } = app;
  const status = organizationsCache[app.organizationId].status;
  
  switch (app.screen) {
    case ScreenEnum.Campaign:
      if(status === "Active") return true;
     else return false;

    case ScreenEnum.Donate:
      if (Number(donationAmount) >= 10 ) return true;
      else return false;
      break;

    case ScreenEnum.Email:
      if (Boolean(email)) return true
      else return false;

    case ScreenEnum.DonationType:
      return true;

    case ScreenEnum.DonorType:
      return Boolean(donorType);

    case ScreenEnum.UserInfo:
      return true;

    case ScreenEnum.UserAddressInfo:
      return true;

    case ScreenEnum.BusinessInfo:
      return true;

    case ScreenEnum.BusinessAddressInfo:
      return true;

    case ScreenEnum.EnterPayment:
      return true;

    case ScreenEnum.ConfirmDonation:
      return true;

    case ScreenEnum.ThankYou:
      return true;
  }

  return false;
};

export default useShowNextHook;
