import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { DonationPortalState } from "../redux/store";
import IOrganization from "@matchstik/models/.dist/interfaces/IOrganization";
import { Colors } from "../styles/Colors";
import ScreenHeader from '../components/ScreenHeader';
import ICreateDonationParams from "@matchstik/models/.dist/interfaces/ICreateDonationParams";
import * as AppActions from '../redux/actions/app.actions';
import { ErrorKeyEnum } from '../redux/reducers/app.reducer';
import LabeledInput from '../elements/LabeledInput';
import useShowNext from "../hooks/useShowNext.hook";
import Checkbox from "../elements/Checkbox";
import Flex from "../elements/Flex";
type ContainerProps = {
  showNext: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  margin-top: -50px;
  background-color: ${Colors.White};
  border-radius: 15px;
  overflow: hidden;
  height: calc(100% - ${props => props.showNext ?"335px": "275px"});
  @media screen and (max-device-width: 991px) and (orientation: landscape){
    overflow:initial;
    height:auto;
   }
`;

const Content = styled.div`
  padding: 0 30px;
  overflow: auto;
  overflow-x: hidden;
  padding-top: 12px;
  padding-bottom: 12px;
  height: calc(100% - 84px);
  @media screen and (max-device-width: 991px) and (orientation: landscape){
    overflow: initial;
    overflow-x: initial;
   }
`;
const CheckBoxText = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${Colors.Grey2};
  margin-left: 10px;
`;

const CheckboxText = styled.div`

`

type BusinessInfoProps = {
  organization: IOrganization;
};

const BusinessInfo: React.FC<BusinessInfoProps> = ({ }) => {
  /** State **/
  const { app } = useSelector((state: DonationPortalState) => state);
  const {name,url,address,}: ICreateDonationParams = app.createDonationParams 
  const errors: any = app.errors
  const showNext = useShowNext();
  /** Actions **/
  const dispatch = useDispatch();
  const setCreateDonationParams = (params: Partial<ICreateDonationParams>) =>
    dispatch(AppActions.setCreateDonationParams(params))
  const setError = (key: ErrorKeyEnum, errorMsg: string) =>
    dispatch(AppActions.setError(key, errorMsg));

    const {
     
      createDonationParams: { allowFeedDisplay = true  },
    } = app;

  /** GraphQL **/

  /** Render **/
  return (
    <Container showNext={showNext}>
      <ScreenHeader title="Contact Info" />
      <Content>  
        <LabeledInput
          autoFocus
          type="text"
          placeholder="Your business name"
          value={name}
          width="100%"
          onChange={(event: React.FormEvent<HTMLInputElement>) => {
            const value = event.currentTarget.value;
            setCreateDonationParams({ name: value });
          }}
          margin="0px 0px 10px 0px"
          error={errors["name"]}
        />
        <LabeledInput
          type="text"
          placeholder="Website"
          value={url || ""}
          width="100%"
          onChange={(event: React.FormEvent<HTMLInputElement>) => {
            const value = event.currentTarget.value;
            setCreateDonationParams({ url: value || "" });
          }}
          margin="0px 0px 10px 0px"
          error={errors["url"]}
        />
      <Flex align="center" margin="20px 0 30px">
            <Checkbox
            // active={true}
            active={allowFeedDisplay as boolean}
            onChange={(active: boolean) =>
              setCreateDonationParams({ allowFeedDisplay : active })
            }
          />
            <CheckBoxText>Post this donation to the Matchstik feed</CheckBoxText>
          </Flex>

      </Content>
    </Container >
  );
};

export default BusinessInfo;
